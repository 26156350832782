<template>
  <div>
    <div class="opt-bar flex">
      <div class="opt-left"> 
        <el-button  @click="onTplSelClick" size="mini"><i class="iconfont iconmoban"></i> 上传模模</el-button>
        <el-button  @click="extJsShowFlag = true" size="mini"><i class="iconfont iconedit"></i> 编辑ext_js</el-button>
        <el-button  @click="onQuery" type="primary" size="mini"><i class="iconfont icontongbu"></i> 刷新</el-button>
      </div>
    </div>
    <split-pane split="vertical" class="pane" :default-percent="40" :resizerThickness="5">
      <template slot="paneL">
        <el-card class="box-card" v-if="info">
            <div class="row">
              <label>名称 ({{transQuota(info.nickname_info)}}/{{info.nickname_info.modify_quota}})</label>
              <span><div class="name">{{info.nickname}}</div></span>
            </div>
            <div class="row">
              <label>APPID</label>
              <span>{{info.appid}}</span>
            </div>
            <div class="row">
              <label>公司</label>
              <span>{{info.principal_name}}</span>
            </div>
            <div class="row">
              <label>信用代码</label>
              <span>{{info.credential}}</span>
            </div>
            <div class="row">
              <label>主体类型</label>
              <span>{{transPricipalType(info.principal_type)}}</span>
            </div>
            <div class="row">
              <label>认证类型</label>
              <span>{{trans_customer_type (info.customer_type)}}</span>
            </div>
            <div class="row">
              <label>头像({{transQuota(info.head_image_info)}}/{{info.head_image_info.modify_quota}})</label>
              <span><img class="headimg" :src="info.head_image_info.head_image_url"></span>
            </div>
            <div class="row">
              <label>认证状态</label>
              <span>{{trans_realname_status  (info.realname_status)}}</span>
            </div>
            <div class="row">
              <label>国家或地区</label>
              <span>{{info.registered_country == 1017 ? '中国' : `其它国家${info.registerd_contry}`}}</span>
            </div>
          </el-card>
      </template>
      <template slot="paneR">      
        <div class="pane-right">
          <div class="extjs">
            <pre>{{ JSON.stringify(platConf.ext_js, null, 4)  }}</pre>            
          </div>
        </div>
      </template>
    </split-pane>
    <!--对话框-->
    <el-dialog
      v-dialogDrag
      title="选择模板"
      :visible.sync="dlgTplShow"
      width="50%"
      >
      <div class="tpls">
        <div class="tpl" @click="onTplSelected(ob)" v-for="ob in tpls" :key="ob.template_id">
          <div class="tplid">模板ID：{{ob.template_id}}<span>版本：{{ob.user_version}}</span></div>
          <div class="desc">{{ob.user_desc}}</div>
          <div class="time">创建时间：{{transTime(ob.create_time)}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dlgTplShow = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="dlgTplShow = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <!--编辑ext_js-->
    <el-drawer class="ext_js"
      title="编辑ext_js"
      :visible.sync="extJsShowFlag"
      :direction="'rtl'"
      :before-close="handleExtJsClose">
      <div class="extjs-edit">
        <vueJsonEditor class="json-editor" 
          v-model="platConf.ext_js" 
          style="height: calc(100vh - 200px);"
          :expandedOnStart="true" :show-btns="false" :mode="'code'" lang="zh">
         </vueJsonEditor>
         <div class="extjs-btns">
           <el-button type="primary" style="width: 160px;">保 存</el-button>           
         </div>
      </div>
    </el-drawer>
  </div>
</template>


<script>
import splitPane from 'vue-splitpane'
import vueJsonEditor from 'vue-json-editor'
import IconBtn from '../../components/btns/IconBtn.vue';
export default {
  components: {   IconBtn, splitPane, vueJsonEditor},
  data(){
    return {
      plat: false,
      platConf: {},
      extJsShowFlag: false,
      info: false,
      dlgTplShow: false,
      tpls: false,
      pricipalTypes: ["个人", "企业", "媒体", "政府", "其它组织"], 
      customerTypes: {1:'企业', 2:'企业媒体', 3: '政府', 4:'非盈利组织',5:'民营非企业', 6:'盈利组织',
        8: '社会团体', 9:'事业媒体', 11:'事业单位', 12: '个体工商户', 14: '海外企业'
      },
    } //--return
  },//--data
  created(){    
    this.onQuery();
    this.loadPlatConf();
  },
  methods: {
    loadPlatConf() {
      var pm = {
        appid: this.global.plat.appid
      }
      this.post('/api/wax/platConf', pm, res=>{
        if (res.code == 0) {
          this.platConf = res.data;
        } else {
          this.$notify.error(res.msg)
        }
      })
    },
    onTplSelected(ob) {
      var msg = `确定要上传该模板到小程序${this.plat.nic_name}, 是否继续?`
      this.$confirm(msg, '确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dlgTplShow = false
          this.commitTemplate(ob);
        })
    },
    commitTemplate(ob) {
      var pm = {
        template_id : ob.template_id,
        appid: this.plat.appid,
        user_version: ob.user_version,
        user_desc: ob.user_desc
      }
      this.post('/api/wax/commitTemplate', pm, res=>{
        if (res.errcode == 0) {
          this.$notify.success('提交成功~')
        } else {
          this.$notify.error(res.errmsg)
        }
      })
    },
    onTplSelClick() {
      this.plat = this.global.plat
      if (!this.tpls) {
        this.queryTpls();
      } else {
        this.dlgTplShow = true;            
      }
    },
    onQuery() {
      var pm = {
        appid : this.global.plat.appid
      }
      this.post('/api/wax/basicInfo', pm, res=>{
        if (res.errcode == 0) {
          this.info = res 
          console.log(res)
        } else {
          this.$notify.error(res.errmsg)
        }
      })
    },
    queryTpls() {
      var pm = {
        template_type: 0
      }
      this.post('/api/waxlib/tplsList', pm, res=>{
        if (res.errcode == 0) {
          this.tpls = res.template_list            
          this.dlgTplShow = true;         
          console.log(this.tpls)
        } else {
          this.$notify.error('获取模板列表失败~' + res.errmsg);
        }
      })
    },
    transTime(dt) {
      var sdt = new Date(dt * 1000)
      return this.util.formatDate(sdt)
    },
    transQuota(obj) {
      return obj.modify_quota - obj.modify_used_count;
    },
    transPricipalType(type) {
      return this.pricipalTypes[type]
    },
    trans_customer_type(tp) {
      return this.customerTypes[tp]
    },
    trans_realname_status(val) {      
      switch(val) {
        case 1:
          return '实名验证'
        case 2:
          return '实名验证中'
        case 3:
          return '实名验证失败'        
      }
      return '未知';
    },
    handleExtJsClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          if (done) {
            done();
          }
        })
        .catch(_ => {});
    },
  }//-methods
};
</script>

<style lang="scss">
.tpls {
  display: flex;
  flex-wrap: wrap;

  .tpl {
    width: 210px;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    padding: 0.3em;
    box-shadow: 0 0 3px #cbcbcb;
    text-align: left;
    cursor: pointer;
    margin: 0.2em;

    .tplid {
      font-weight: 700;      

      span{
        font-weight: 300;
        color: gray;
        font-size: 0.9em;
        float: right;
      }
    }
    .desc {
      margin-top: 0.5em;
      font-size: 0.9em;
      text-align: left;
    }
    .time {
      margin-top: 0.5em;
      text-align: left;
      font-size: 0.9em;
      color: green;
    }
  }
  .tpl:hover {
    background-color: #f8f8f8;
  }
}
.box-card {
  
}
.row {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
  border-bottom: 1px solid #f8f8f8;
  min-height: 32px;
  padding-top: 0.4em;
  span {
    flex: 1 1 auto;
    text-align: right;    
  }
  .name {
    font-size: 1.5em;
    font-weight: 700;
    color: green;
  }

  .headimg{
    width: 100px;
    height: 100px;
  }
}
.ext_js {
  text-align: left;
}
.extjs-edit {
  height: 500px;  
}
.json-editor {
  text-align: left;    
}
.jsoneditor-vue{
  height: 100% !important;
  
}
.jsoneditor-vue .jsoneditor-outer{
  height: 100%;  
}
.extjs-btns {
  text-align: center;
  margin-top: 0.5em;
}
</style>